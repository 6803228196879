import React, {Component} from 'react'
import FormConexoes from '../FormConexoes/FormConexoes'
import TabelaConexoes from '../FormConexoes/listaConexoes';
import './WindowConexoes.css'
import Metro from 'metro4/build/js/metro'

const port = process.env.REACT_APP_HOST_PORT || 5000;
const url_host = process.env.REACT_APP_HOST || 'extrator.sysepa.com.br';
const apiURL = `http://${url_host || 'extrator.sysepa.com.br'}:${port}/api`;
class WindowConexoes extends Component {
    constructor(props) {
        super(props)

        const {closeFunction} = this.props;
        this.closeFunction = closeFunction;

        this.initialState = {
            odbcString: '',
            formType: null,
            data: null,
        };

        this.state = this.initialState;
        this.windowId = 'window-Conexoes';
        this.title = 'Configurações de conexão';

    }

    componentDidMount() {
        document.getElementById('overlay').style.display = 'block';
        document.getElementById('overlay').style.zIndex = 3;
    }

    /**
     *
     * @param conexao
     * @private
     */
    _loadConexao(conexao){
        this.setState({formType: 'edit'});
        this.setState({data: conexao});
    }

    async _validateConnection(conexao){
        const headers = new Headers({
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'empresa': localStorage.getItem('empresa'),
            'codigo': localStorage.getItem('codigo'),
            'auth': localStorage.getItem('auth'),
        });
        const options = {
            method: 'GET',
            headers: headers,
            mode: 'cors'
        };

        fetch(apiURL + `/conexoes/validar/${conexao.id}`, options)
            .then(res => res.json())
            .then(data => {
                if(data.validate === true){
                    this.showMessage('Conexão realizada com sucesso', 'success');
                } else {
                    this.showMessage('Não foi possível conectar ao banco informado', 'error');
                }
            });

    }
    /**
     *
     * @param conexao
     * @returns {Promise<void>}
     * @private
     */
    async _removeConexao(conexao){
        const headers = new Headers({
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'empresa': localStorage.getItem('empresa'),
            'codigo': localStorage.getItem('codigo'),
            'auth': localStorage.getItem('auth'),
        });
        const options = {
            method: 'DELETE',
            headers: headers,
            mode: 'cors'
        };
        await fetch(apiURL + `/conexoes/${conexao.id}`, options);
        this.closeFunction();
    }

    showMessage(message, type) {
        if (type === 'success') {
            Metro.dialog.create({
                title: 'Ótimo!',
                content: `<span class="h4 p-0 m-0">${message}</span>`,
                actions: [{caption: "OK", cls: "js-dialog-close bg-darkBlue hover-lightBlue fg-white"}],
                defaultAction: {},
                overlayClickClose: true,
                clsTitle: 'bg-darkGreen fg-white p-5 m-0',
                clsContent: 'bg-darkGreen fg-white p-5 m-0',
                clsAction: 'bg-darkGreen fg-white p-5 m-0',
                removeOnClose: true,
                show: true
            })
        } else {
            Metro.dialog.create({
                title: 'Falha de conexão',
                content: `<span class="h4 p-0 m-0">${message}</span>`,
                actions: [{caption: "OK", cls: "js-dialog-close bg-darkRed hover-lightRed fg-white"}],
                defaultAction: {},
                overlayClickClose: true,
                clsTitle: 'bg-darkRed fg-white p-5 m-0',
                clsContent: 'bg-darkRed fg-white p-5 m-0',
                clsAction: 'bg-red fg-white p-5 m-0',
                removeOnClose: true,
                show: true
            })
        }

    }

    render() {

        if (this.state.formType == 'save' || this.state.formType == 'edit') {
            const form = <FormConexoes data={this.state.data} closeFunction={this.closeFunction} formType={this.state.formType} />;
            return (
                <div className="window Conexoes-window" id={this.windowId}>
                    <div className="window-caption">
                        <span className="icon mif-pencil"/>
                        <span className="title">{this.title}</span>
                        <div className="buttons">
                            <span className="btn-close" onClick={() => this.closeFunction()}/>
                        </div>
                    </div>
                    <div className=" p-2">
                        <div className="grid">
                            <div className="row">
                                <div className="cell">
                                </div>
                            </div>
                            <div className="row">
                                <div className="cell">
                                    <div>
                                        {form}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        } else {
            return (
                <div className="window Conexoes-window" id={this.windowId}>
                    <div className="window-caption">
                        <span className="icon mif-pencil"/>
                        <span className="title">{this.title}</span>
                        <div className="buttons">
                            <span className="btn-close" onClick={() => this.closeFunction()}/>
                        </div>
                    </div>
                    <div className="p-2">
                        <div className="grid">
                            <div className="row">
                                <div className="cell p-3">
                                    <button
                                        className="button bg-blue fg-white rounded float-right"
                                        onClick={() => this.setState({formType: 'save'})}
                                    >
                                        <span className="icon mif-plus" />
                                    </button>
                                </div>
                            </div>
                            <div className="row">
                                <div className="cell">
                                    <div>
                                        <TabelaConexoes
                                            validateConnection = {(e) => this._validateConnection(e)}
                                            removeConexao={(e) => this._removeConexao(e)}
                                            loadConexao={(e) => this._loadConexao(e)}
                                            closeFunction={this.closeFunction}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }
}

export default WindowConexoes