import React from 'react';
import ReactDOM from 'react-dom';

import './index.css';
import '../node_modules/metro4/build/css/metro-all.min.css'
import '../node_modules/metro4/build/js/metro'

import App from './App';
import ErrorBoundary from './Error';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(<App />, document.getElementById('root'));

serviceWorker.unregister();
