import React, {Component} from 'react';
import Select from 'react-select';

import './select.css';
class SelectClassificacao extends Component {
    constructor(props){
        super(props);
        this.state = { opcoes: this.props.opcoes, classificacao: 'Todas', selectedOption: 'Todas', }
    }

 
    componentWillReceiveProps(props){
        const {opcoes} = props;
        this.setState({opcoes: opcoes});
    }

    options(){
        let data = [{
            value: 'Todas',
            label: 'Todas'
        }];
        if(this.state.opcoes.length >= 1){
            this.state.opcoes.map((val, ind) => {
                data.push({
                    value: val.id,
                    label: val.nome,
                })
                return val.id;
            })
        }
        return data;
    }

    handleChange = selectedOption => {
        this.setState({ selectedOption });
        this.props.selected(selectedOption)
    };
        
    render() {
        const { selectedOption } = this.state;
        return (
            <div>
                <Select
                    className="selectTop"
                    data-role="select"
                    placeholder="Selecione a classificação"
                    name="classificacao"
                    value={selectedOption}
                    onChange={this.handleChange}
                    options={this.options()}
                />
            </div>
        );
    }
}

export default SelectClassificacao;