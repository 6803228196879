import React, { Component } from 'react';
import ReactDOM from 'react-dom'

import WindowIndicador from '../../indicador/WindowIndicador/WindowIndicador'
import WindowClassificacao from '../../indicador/WindowClassificacao/WindowClassificacao'
import WindowConfiguracoes from '../../configuracoes/WindowConfiguracoes/WindowConfiguracoes'
import WindowConexoes from '../../configuracoes/WindowConexoes/WindowConexoes'
import WindowLogs from '../../configuracoes/WindowLogs/WindowLogs'

import './Menu.css'

class Menu extends Component {
  constructor(props) {
    super(props)
    this.fecharWindow.bind(this)

    const { reloadFunction } = this.props
    this.reloadFunction = reloadFunction
  }

  fecharWindow = () => {
    if(document.getElementById('windows-container').childElementCount > 0){
      ReactDOM.unmountComponentAtNode(document.getElementById('windows-container'))
    }
    document.getElementById('overlay').style.display = 'none'
    this.reloadFunction()
  }

  novoIndicador(indicador) {
    ReactDOM.render(<WindowIndicador indicador={indicador} closeFunction={this.fecharWindow} />,
      document.getElementById('windows-container'))
  }

  novaClassificacao() {
    ReactDOM.render(<WindowClassificacao closeFunction={this.fecharWindow} />,
      document.getElementById('windows-container'))
  }

  abrirConfiguracoes() {
    ReactDOM.render(<WindowConfiguracoes closeFunction={this.fecharWindow} />,
      document.getElementById('windows-container'))
  }

  abrirConexoes() {
    ReactDOM.render(<WindowConexoes closeFunction={this.fecharWindow} />,
      document.getElementById('windows-container'))
  }

  abrirLogs() {
    ReactDOM.render(<WindowLogs closeFunction={this.fecharWindow} />,
      document.getElementById('windows-container'))
  }

  render() {
    return (
      <div className="bottom-nav bg-dark">
          <button className="button fg-white bg-darkCobalt-hover bg-cobalt" onClick={() => this.novoIndicador()}>
            <span className="icon mif-plus"></span>
            <span className="label">Adicionar Indicador</span>
          </button>
          <button className="button fg-white bg-darkBlue-hover bg-blue" onClick={() => this.novaClassificacao()}>
            <span className="icon mif-plus"></span>
            <span className="label">Classificações</span>
          </button>
          <button className="button fg-white bg-darkBrown-hover bg-brown" onClick={() => this.abrirConexoes()}>
            <span className="icon mif-database"></span>
            <span className="label">Conexões</span>
          </button>
          {/*<button className="button fg-white bg-darkCobalt-hover bg-cobalt" onClick={() => this.abrirConfiguracoes()}>*/}
          {/*  <span className="icon mif-cog"></span>*/}
          {/*  <span className="label">Configurações</span>*/}
          {/*</button>*/}
        <button className="button fg-white bg-darkCobalt-hover bg-cobalt" onClick={() => this.abrirLogs()}>
            <span className="icon mif-history"></span>
            <span className="label">Logs</span>
          </button>
      </div>
    )
  }
}

export default Menu
