import React, {Component} from 'react';
import Metro from 'metro4';
import './Sidebar.css';

class Sidebar extends Component {
    constructor(props) {
        super(props);

        const {messagesList} = this.props;

        if (Array.isArray(messagesList)) {
            this.state = {messagesList: messagesList}
        } else {
            this.state = {messagesList: []}
        }

        this.adicionarMensagem.bind(this);

        this.sidebarId = 'sidebar-mensagens';
        this.messageCount = 0
    }


    componentWillReceiveProps(props) {
        const {refresh} = this.props;
        if (refresh !== props.refresh) {
            let mensagem = props.messagesList[this.messageCount];
            mensagem.mensagem.chave = this.messageCount++;
            this.adicionarMensagem(mensagem.mensagem, mensagem.type)
        }
    }

    adicionarMensagem = (mensagem, type) => {
        if (typeof mensagem.content === 'string' && typeof mensagem.title === 'string') {
            const itemClasses = ['fg-white', 'border', 'border-size-2', 'mb-1', 'p-2'];
            const iconClasses = ['mif-4x'];
            if (type === 'error') {
                itemClasses.push('bg-darkAmber');
                iconClasses.push('mif-sync-problem')
            } else if (type === 'success') {
                itemClasses.push('bg-darkEmerald');
                iconClasses.push('mif-done')
            } else if (type === 'warning') {
                itemClasses.push('bg-darkYellow');
                iconClasses.push('mif-done')
            } else if (type === 'info') {
                itemClasses.push('bg-darkBlue');
                iconClasses.push('mif-done')
            }

            mensagem.itemClass = itemClasses.join(' ');
            mensagem.iconClass = iconClasses.join(' ');
            this.setState(state => {
                const mensagens = state.messagesList.concat(mensagem);
                mensagens.sort((a, b) => b.chave - a.chave);
                return {messagesList: mensagens}
            });

            Metro.sidebar.open(`#${this.sidebarId}`);
            window.scrollTo(0, 0);
        }
    };

    removerMensagem = (id) => {
        if (id === null) {
            this.setState({messagesList: []});
            this.messageCount = 0
        } else if ( this.messageCount > 0) {
          console.log(this.state.messagesList);
            const filteredMessages = this.state.messagesList.filter(mensagem => mensagem.chave !== id);
            this.setState(state => {
                return {messagesList: filteredMessages};
            });
        }

    };

    SidebarItem = function (mensagem) {
        return (
            <li key={mensagem.chave} className={mensagem.itemClass}>
                <button className="button mini cycle float-right bg-red bd-darkRed fg-white"
                        onClick={() => this.removerMensagem(mensagem.chave)}>
                    <span className="icon"><span className="mif-cross"></span></span>
                </button>
                <h6 className="text-bold text-center">
                    <p><span className={mensagem.iconClass}></span></p>
                    <div>{mensagem.title}</div>
                </h6>
                <div className="mb-5">{mensagem.content}</div>
            </li>
        )
    };

    render() {
        const cardsMensagens = (this.state.messagesList || []).map(mensagem => {
            return this.SidebarItem(mensagem)
        });

        return (
            <span>
        <aside className="sidebar pos-absolute z-2 border bd-dark border-size-2 z-10"
               data-role="sidebar"
               data-toggle=".button-toggle-sidebar"
               data-static-shift=".shifted-content"
               id={this.sidebarId}>
          <div className="sidebar-header bg-cobalt">
            <button className="sub-action button button-toggle-sidebar bg-darkCobalt fg-white">
              <span className="mif-arrow-left mif-2x"></span>
            </button>
            <span className="title fg-white text-bold">Lista de Mensagens</span>
            <span className="subtitle fg-white">Aqui você acompanha a sincronização dos indicadores</span>
          </div>
          <ul className="sidebar-menu">
            {cardsMensagens}
          </ul>
        </aside>
        <div className="h-100 z-3">
          <button className="action-button pos-absolute pos-top-right bg-darkCobalt button-toggle-sidebar mt-2 mr-2">
            <span className="icon fg-white"><span className="mif-comment"></span></span>
          </button>
        </div>
      </span>
        );
    }
}

export default Sidebar
