import React, { Component } from 'react'

import './MapaSincronizacoes.css'


const port = process.env.REACT_APP_HOST_PORT || 5000
const url_host = process.env.REACT_APP_HOST || 'extrator.sysepa.com.br'
const apiURL = `http://${url_host || 'extrator.sysepa.com.br'}:${port}/api`

class MapaSincronizacoes extends Component {
    constructor(props) {
        super(props)

        this.state = { mapaSincronizacoes: {} }
        this.idComponente = 'stepper-sincronizacoes'
        this.idDivHints = 'divHints-horariosSincronizacao'
    }

    buscarMapaSincronizacao = async () => {
        let myHeaders = new Headers();
        myHeaders.append('empresa', localStorage.getItem('empresa'));
        myHeaders.append('codigo', localStorage.getItem('codigo'));
        myHeaders.append('auth', localStorage.getItem('auth'));
        
        const myInit = { 
            method: 'GET',
            headers: myHeaders,
            mode: 'cors',
            cache: 'default' 
        };
        const response = await fetch(apiURL + '/configuracoes', myInit)
        const body = await response.json()
        if (response.status !== 200) throw Error(body.message)

        await this.setState({ mapaSincronizacoes: body.mapaSincronizacoes })
    }

    componentDidMount() {
        this.buscarMapaSincronizacao()
    }


    render() {
        if(!this.state.mapaSincronizacoes) return null;
        // const horarios = Object.keys(this.state.mapaSincronizacoes)
        // const linhasHorarios = horarios.map(horario => {
        //     const indicadoresDesteHorario = this.state.mapaSincronizacoes[horario].join(',')
        //     return (
        //         <tr>
        //             <td>{horario}</td>
        //             <td>{indicadoresDesteHorario}</td>
        //         </tr>
        //     )
        // })

        return (
            <div className="">
                {/* <h3 className="text-center">Horários de sincronização</h3>
                <table className="table">
                    <thead>
                        <th className="sortable-column sort-asc">Hora</th>
                        <th>Indicadores</th></thead>
                    <tbody>
                        {linhasHorarios}
                    </tbody>
                </table> */}
            </div>
        )
    }
}

export default MapaSincronizacoes