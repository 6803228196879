import React, {useEffect, useRef, useState} from 'react';

function SearchInput({onChange, value, placeholder = '', style={}})
{
    return (
            <input
                style={style}
                className={'h-100 rounded input-control text'}
                placeholder={placeholder}
                value={value}
                onChange={event => onChange(event.target.value)}
                data-role="input"
                data-clear-button="false"
                data-search-button="false"
                data-search-button-click="custom"
                data-on-search-button-click=""
                type="text"
            />
    );
}

export default SearchInput;