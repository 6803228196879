import React, { Component } from 'react'
import FormIndicador from '../FormIndicador/FormIndicador'

import './WindowIndicador.css'

class WindowIndicador extends Component {
  constructor(props) {
    super(props)

    const { indicador, closeFunction } = this.props

    this.closeFunction = closeFunction

    this.initialState = {
      nome: '',
      odbcString: '',
      sqlScript: '',
      limitedSqlScript: '',
      dateFormat: '',
      codigoEpa: 0,
      horariosSincronizacao: [],
      indicadoresFilhos: [],
      retroativo: null
    };

    if (this.props.indicador) {
      this.state = indicador
      this.windowId = 'window-indicador-' + this.state.id
      this.title = indicador.nome
    } else {
      this.state = this.initialState
      this.windowId = 'window-indicador-new'
      this.title = 'Novo Indicador'
    }
  }

  componentDidMount() {
    document.getElementById('overlay').style.display = 'block'
    document.getElementById('overlay').style.zIndex = 3
  }

  render() {
    const form = <FormIndicador indicador={this.state} closeFunction={this.closeFunction} />

    return (
      <div className="window indicador-window w-75" id={this.windowId}>
        <div className="window-caption">
          <span className="icon mif-pencil"></span>
          <span className="title">{this.title}</span>
          <div className="buttons">
            <span className="btn-close" onClick={() => this.closeFunction()}></span>
          </div>
        </div>
        <div className="window-content p-2">
          {form}
        </div>
      </div>
    )
  }
}

export default WindowIndicador