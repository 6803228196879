import React, { Component } from "react";
import ReactDOM from "react-dom";
import InputMask from "react-input-mask";
import Metro from "metro4/build/js/metro";
import WindowIndicador from "../../indicador/WindowIndicador/WindowIndicador";
import io from "socket.io-client";
import "./TabelaIndicadores.css";
import SelectClassificacao from "../SelectClassificacao/SelectClassificacao";
import SearchInput from "../Inputs/SearchInput";
import DateInput from "../Inputs/DateInput";

const port = process.env.REACT_APP_HOST_PORT || 5000
const url_host = process.env.REACT_APP_HOST || 'extrator.sysepa.com.br'
const socket = io(`http://${url_host || 'extrator.sysepa.com.br'}:` + port, {
	reconnect: true,
	transports: ["websocket"],
	query: 'empresa='+localStorage.getItem('empresa') || ''
});


const apiURL = `http://${url_host || 'extrator.sysepa.com.br'}:${port}/api`
class TabelaIndicadores extends Component {
	constructor(props) {
		super(props);

		const { reloadFunction, alertFunction } = this.props;



		this.reloadFunction = reloadFunction;
		this.alertFunction = alertFunction;
		this.fecharWindows.bind(this);
		this.handleSelected = this.handleSelect.bind(this);
		this.state = {
			indicadores: [],
			classificacoes: [],
			classificacao: "Todas",
			datainicio: new Date(
				new Date().getFullYear(),
				new Date().getMonth(),
				1
			).toLocaleDateString("pt-BR"),
			datafim: new Date().toLocaleDateString("pt-BR"),
			ativo: {
				label: 'Ativo',
				value: 1
			},
			search: ''
		};

		socket.connect();

		socket.on("", indicador => {
			const title = `Sincronização do indicador ${indicador.nome} foi iniciada`;
			alertFunction(this.prepararMensagem(title, null), "warning");
		});
		socket.on("indicador:sincronizar:sucesso", indicador => {
			const title = `Sincronização do indicador ${indicador.nome} realizada com sucesso`;
			alertFunction(this.prepararMensagem(title, null), "success");
			this.reloadFunction();
		});
		socket.on("indicador:sincronizar:erro", (indicador, erro) => {
			const title = `Falha ao sincronizar o indicador: ${indicador.nome}`;
			alertFunction(this.prepararMensagem(title, erro), "error");
			this.reloadFunction();
		});

		socket.on("indicador:desbloquear:sucesso", indicador => {
			const title = `O Indicador foi desbloqueado`;
			alertFunction(this.prepararMensagem(title, null), "success");
			this.reloadFunction();
		});

		socket.on("indicador:desbloquear:erro", (indicador, error) => {
			const title = `Erro ao desbloquear o indicador.`;
			alertFunction(this.prepararMensagem(title, error), "error");
			this.reloadFunction();
		});

		socket.on('indicador:sincronizar:all:sucesso', data => {
			const title = `Foi agendada a sincronização dos indicadores.`;
			alertFunction(this.prepararMensagem(title, null), "success");
			this.reloadFunction();
		});

		socket.on('indicador:progresso', indicator => {

			const indicadores = this.state.indicadores;
			const index = indicadores.findIndex(indicador => {
				return indicador.id === indicator.id
			});

			if(index < 0) return;

			this.setState(({indicadores}) => {
				const indicadorUpdated = indicadores[index];
				indicadorUpdated.progress = indicator.progress || 0;
				indicadores[index] = indicadorUpdated;
				return { indicadores };
			});
		});
	}

	prepararMensagem = function (title, erro) {
		const now = new Date();
		if (erro === null) {
			return {
				title: title,
				content: now.toLocaleString("pt-BR", { timeZone: "America/Sao_Paulo" })
			};
		} else if (typeof erro === "string") {
			return {
				title: title,
				content:
					now.toLocaleString("pt-BR", { timeZone: "America/Sao_Paulo" }) +
					" - " +
					erro
			};
		}
		return null;
	};

	async componentDidMount() {
		// const headers = new Headers({
		// 	'Content-Type': 'application/json',
		// 	'Accept': 'application/json',
		// 	'empresa': localStorage.getItem('empresa'),
		// 	'codigo': localStorage.getItem('codigo'),
		// 	'auth': localStorage.getItem('auth'),
		// });
		// const options = {
		// 	method: 'GET',
		// 	headers: headers,
		// 	mode: 'cors'
		// };

		// const response = await fetch(apiURL + `/configuracoes/data`, options);
		// const body = await response.json();
		// // if (response.status !== 200) window.location.href = 'http://simeon.com.br';
		// this.setState({ datainicio: body.data_inicio, datafim: body.data_fim})

	}

	saveData = e => {
		if (this.state.datafim && this.state.datainicio &&
			this.state.datainicio.trim().length !== 0 && this.state.datafim.trim().length !== 0 &&
			(this.state.datainicio.trim().length < 10 || this.state.datafim.trim().length < 10)) this.showError(`Informar as datas é obrigatório`)
		const headers = new Headers({
			'Content-Type': 'application/json',
			'Accept': 'application/json',
			'empresa': localStorage.getItem('empresa'),
			'codigo': localStorage.getItem('codigo'),
			'auth': localStorage.getItem('auth'),
		});
		const options = {
			method: 'POST',
			headers: headers,
			body: JSON.stringify({ datainicio: this.state.datainicio, datafim: this.state.datafim }),
			mode: 'cors'
		};
		document.getElementById('overlay').style.display = 'block';
		document.getElementById('overlay').style.zIndex = 3;
		fetch(apiURL + '/configuracoes/data', options)
			.then(this.handleErrors)
			.then(res => {
				this.reloadFunction()
			})
			.catch(err => {
				if (err.message) this.showError(err.message)
				else this.showError('Houve um problema ao remover o Indicador!')
			})
			.finally(() => document.getElementById('overlay').style.display = 'none')
	}
	handleData = e => {
		const name = e.target.name;
		this.setState({ [name]: e.target.value });
	};

	componentWillReceiveProps(props) {
		const { refresh, indicadores, classificacoes } = props;
		if (this.props.refresh !== refresh) {
			this.setState({ indicadores: indicadores });
			this.setState({ classificacoes: classificacoes });
		}
	}

	fecharWindows = () => {
		ReactDOM.unmountComponentAtNode(
			document.getElementById("windows-container")
		);
		this.reloadFunction();
		document.getElementById("overlay").style.display = "none";
	};

	handleErrors(response) {
		if (!response.ok) {
			throw new Error(response.error);
		}
		return response;
	}

	showError(message) {
		Metro.dialog.create({
			title: "Erro ao salvar",
			content: `<span class="h4 p-0 m-0">${message}</span>`,
			actions: [
				{
					caption: "OK",
					cls: "js-dialog-close bg-darkRed hover-lightRed fg-white"
				}
			],
			defaultAction: {},
			overlayClickClose: true,
			clsTitle: "bg-darkRed fg-white p-5 m-0",
			clsContent: "bg-darkRed fg-white p-5 m-0",
			clsAction: "bg-red fg-white p-5 m-0",
			removeOnClose: true,
			show: true
		});
	}

	criarWindowIndicador = indicador => {
		document.getElementById("overlay").style.display = "block";
		ReactDOM.render(
			<WindowIndicador
				indicador={indicador}
				closeFunction={this.fecharWindows}
			/>,
			document.getElementById("windows-container")
		);
	};

	desbloquearInidcador = indicador => {
		const uniqueId = parseInt(indicador.id);
		if (!uniqueId) return false;
		socket.emit("indicador:desbloquear", {
			indicador: indicador,
			empresa: localStorage.getItem('empresa'),
		});
	};

	removerIndicador = indicador => {
		const uniqueId = indicador.id;
		if (!uniqueId) {
			return false;
		}
		const headers = new Headers({
			'empresa': localStorage.getItem('empresa'),
			'codigo': localStorage.getItem('codigo'),
			'auth': localStorage.getItem('auth'),
		})
		const options = {
			method: "DELETE",
			headers: headers,
			mode: "cors"
		};

		document.getElementById("overlay").style.display = "block";
		fetch(apiURL + `/indicador/${uniqueId}`, options)
			.then(this.handleErrors)
			.then(res => {
				this.reloadFunction();
			})
			.catch(err => {
				if (err.message) this.showError(err.message);
				else this.showError("Houve um problema ao remover o Indicador!");
			})
			.finally(
				() => (document.getElementById("overlay").style.display = "none")
			);
	};

	reprogramarCronTab = async () => {
		const headers = new Headers({
			'Content-Type': 'application/json',
			'Accept': 'application/json',
			'empresa': localStorage.getItem('empresa'),
			'codigo': localStorage.getItem('codigo'),
			'auth': localStorage.getItem('auth'),
		});
		const options = {
			method: 'GET',
			headers: headers,
			mode: 'cors'
		};
		const response = await fetch(apiURL + `/configuracoes/crontab/sincronizar`, options);
		await response.json();
		if (response.status === 200) {
			const title = `Crontab reagendado com sucesso!`;
			this.alertFunction(this.prepararMensagem(title, null), "success");
		};

	};

	sincronizarTodosIndicadores = async () => {
		let indicadores = [];
		this.state.indicadores.map((indicador, indice) => {
			if (
				this.state.classificacao !== "Todas" &&
				indicador.classificacao !== this.state.classificacao
			) return [];

			indicadores.push(indicador.id);

			return [];
		});
		socket.emit("indicador:sincronizar:all",
			{
				indicadores,
				dataInicio: this.state.datainicio,
				dataFim: this.state.datafim,
				empresa: localStorage.getItem('empresa')
			}
		);
	};

	sincronizarIndicador = async (indicador, indice) => {
		this.setState(data => {
			data.indicadores[indice].locked = 1;
			return { data };
		});

		const uniqueId = indicador.id;
		if (!uniqueId) return false;

		socket.emit("indicador:sincronizar", {
			indicador: indicador,
			dataInicio: this.state.datainicio,
			dataFim: this.state.datafim,
			empresa: localStorage.getItem('empresa')
		});
	};

	progressBar(indicador) {
		const isLocked = Boolean(indicador.locked);
		if (!isLocked) return null;
		return (
			<div
				data-role="progress"
				data-value={indicador.progress || '0'}
				className="progress"
			/>
		);
	}

	handleAtivo = ativo => {
        this.setState({ ativo });
	};

	handleChange = e => {
		const name = e.target.name;
		this.setState({ [name]: e.target.value });
	};

	handleSearch = search => {
		this.setState({search});

		// const indicadores = Object.assign([], this.state.indicadores);
		//
		// this.setState({
		// 	indicadores: indicadores.filter(indicador => indicador.nome.includes(search))
		// });

	};

	handleSelect(selected) {
		this.setState({ classificacao: selected.value });
	}
	render() {
		const header = () => {
			return (
				<div>
					<div className="row py-4 borda rounded">
						<div className="cell-12">
							<div className="row">
								<div className="cell-3">
									<DateInput
										label={'Data Início'}
										name={'datainicio'}
										onChange={this.handleData}
										value={this.state.datainicio}
									/>
								</div>
								<div className="cell-3">
									<DateInput
										label={'Data Fim'}
										name={'datafim'}
										onChange={this.handleData}
										value={this.state.datafim}
									/>
								</div>
								<div className="cell-6">
									<div className="form-group">
										<button
											data-role="hint"
											className="button rounded bg-blue mx-2-md"
											data-hint-hide="2000"
											data-hint-text={"Sincroniza todos os indicadores."}
											data-hint-position="top"
											data-cls-hint={"rounded text-size-greater bg-blue"}
											onClick={() => this.sincronizarTodosIndicadores()}
										>
											<span className="mif-loop2" />
											&nbsp;Sincronizar Todos
										</button>
										<button
											data-role="hint"
											className="button rounded bg-blue mx-2-md"
											data-hint-hide="4000"
											data-hint-text={"Reprograma a fila de execução de acordo com os horários configurados nos indicadores."}
											data-hint-position="right"
											data-cls-hint={"rounded text-size-greater bg-blue"}
											onClick={() => this.reprogramarCronTab()}
										>
											<span className="mif-alarm"/>
											&nbsp;Reagendar Sincronizações
										</button>
									</div>
								</div>
							</div>
						</div>
						<div className="cell-12 pt-4 text-left">
							<div className="form-group pt-2">
								<div className="row">
									<div className="cell-6">
										<SelectClassificacao
											opcoes={this.state.classificacoes}
											selected={this.handleSelected}
										/>
									</div>
									<div className="cell-6">
										<SearchInput
											style={{
												height: '100%'
											}}
											placeholder={'Pesquise por indicadores...'}
											onChange={this.handleSearch}
											value={this.state.search}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			);
		};

		let rows;

		if(this.state.indicadores.length >= 1){
			rows = this.state.indicadores
				.filter(indicador => (
					indicador.nome.toLowerCase().includes(this.state.search.toLowerCase()) ||
					indicador.codigo_epa.toLowerCase().includes(this.state.search.toLowerCase())
				))
				.map((indicador, indice) => {
				if (
					((this.state.classificacao !== "Todas" ||
						this.state.classificacao === null) &&
					indicador.extrator_classificacoes_id !== this.state.classificacao)
				)
					return null;
				const locked = indicador.locked == 1 ? true : false;
				const hintText = indicador.locked
					? "Este indicador não pode ser alterado até que termine de ser sincronizado"
					: "Indicador Sincronizado";
				const hintClass = "rounded text-size-greater ";

				return (
					<div
						className="row my-1 rounded bg-lightGray bg-darkGray-hover z-1"
						key={"row-" + indicador["id"]}
						data-role="hint"
						data-hint-hide="2000"
						data-hint-text={hintText}
						data-hint-position="bottom"
						data-cls-hint={hintClass + (indicador.locked ? "bg-red" : "bg-green")}
					>
						<div className="cell-8 text-left">
							<span className='flex-align-center' style={{
								display: 'flex',
								height: '100%',
							}}>{indicador.codigo_epa} - {indicador.nome}</span>
						</div>
						<div className="cell-4 py-2 text-right">
							<button
								disabled={locked}
								className="button rounded bg-blue mx-2-md small"
								onClick={() => this.sincronizarIndicador(indicador, indice)}
							>
								<span className="mif-loop2"></span>
							</button>
							<button
								disabled={locked}
								className="button rounded bg-green mx-2-md btn-abrir-indicador small"
								onClick={evt => {
									return this.criarWindowIndicador(indicador);
								}}
							>
								<span className="mif-pencil"></span>
							</button>
							<button
								disabled={locked}
								className={indicador.excluido ? "button rounded bg-orange mx-2-md small" : "button rounded bg-red mx-2-md small"}
								onClick={() => this.removerIndicador(indicador)}
							>
								<span className={indicador.excluido ? "mif-checkmark": "mif-cross"} />
							</button>
							<button
								disabled={!locked}
								hidden={!locked}
								className="button rounded bg-orange mx-2-md"
								onClick={() => this.desbloquearInidcador(indicador)}
							>
								<span className="mif-unlock"></span>
							</button>
						</div>
						{this.progressBar(indicador)}
					</div>
				);
			});
		}


		return (
			<div className="grid" id="tabela-indicadores">
				{header()}
				{rows}
			</div>
		);
	}
}

export default TabelaIndicadores;
