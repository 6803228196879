import React, {Component} from 'react';
import './App.css';
import Menu from './ui-components/index/Menu/Menu';
import TabelaIndicadores from './ui-components/index/TabelaIndicadores/TabelaIndicadores'
import Sidebar from './ui-components/index/Sidebar/Sidebar'

const port = process.env.REACT_APP_HOST_PORT || 5000;
const url_host = process.env.REACT_APP_HOST || 'extrator.sysepa.com.br';
const apiURL = `http://${url_host || 'extrator.sysepa.com.br'}:${port}/api`;

class App extends Component {
    constructor(props) {
        super(props);
        let url = new URLSearchParams(window.location.search);
        if (url.has('token')) {
            localStorage.setItem('empresa', url.get('empresa'));
            localStorage.setItem('codigo', url.get('codigo'));
            localStorage.setItem('auth', url.get('token'));
            window.location.search = '';
        }
        this.state = {indicadores: [], listaMensagens: [], classificacoes: [], session_expired: false};
        this.buscarIndicadores.bind(this);
        this.enviarMensagem.bind(this);
        this.buscarClassificacoes.bind(this);
        this.reloadIndicadores = false;
        this.reloadMessages = false
    }

    buscarIndicadores = async () => {
        this.buscarClassificacoes();
        document.getElementById('overlay').style.display = 'block';
        document.getElementById('overlay').style.zIndex = 55;

        let myHeaders = new Headers();
        myHeaders.append('empresa', localStorage.getItem('empresa'));
        myHeaders.append('codigo', localStorage.getItem('codigo'));
        myHeaders.append('auth', localStorage.getItem('auth'));

        const myInit = {
            method: 'GET',
            headers: myHeaders,
            mode: 'cors',
            cache: 'default'
        };

        const response = await fetch(apiURL + '/indicador', myInit);
        const body = await response.json();
        if (response.status !== 200) throw Error(body.message);

        this.reloadIndicadores = !this.reloadIndicadores;
        this.setState({indicadores: body});
        document.getElementById('overlay').style.display = 'none'
    };

    buscarClassificacoes = async () => {
            let myHeaders = new Headers();
            myHeaders.append('empresa', localStorage.getItem('empresa'));
            myHeaders.append('codigo', localStorage.getItem('codigo'));
            myHeaders.append('auth', localStorage.getItem('auth'));

            const myInit = {
                method: 'GET',
                headers: myHeaders,
                mode: 'cors',
                cache: 'default'
            };
            const response = await fetch(apiURL + '/classificacoes', myInit);
            const body = await response.json();
            // if (response.status !== 200) throw Error(body.message);
            this.setState({classificacoes: body})
    };

    enviarMensagem = (mensagem, type) => {
        const novaMensagem = {
            mensagem: mensagem,
            type: type
        };
        this.reloadMessages = !this.reloadMessages;
        let arr = [];
        this.setState(state => {
            const listaMensagens = arr.concat(novaMensagem, this.state.listaMensagens);
            return {listaMensagens: listaMensagens.reverse()}
        })
    };

    componentDidMount() {
            (async () => {
                try{
                    await this.buscarIndicadores();
                    await this.buscarClassificacoes();
                } catch(e){
                     this.setState({session_expired: true});
                }
            })()
    }

    render() {
      if(this.state.session_expired === true){
        return (
            <h1 className={"text-center"}>Carregando....</h1>
        )
      }
        return (
            <div>
                <Sidebar messagesList={this.state.listaMensagens} refresh={this.reloadMessages} />
                <div className="h-100 shifted-content">
                    <div className="p-5 mb-5 bg-white border-bottom borda">
                        <div className="cell-6">
                            <img src={require('./logo.svg')} />
                        </div>
                    </div>
                    <div className="container">
                        <TabelaIndicadores
                            classificacoes={this.state.classificacoes}
                            indicadores={this.state.indicadores}
                            refresh={this.reloadIndicadores}
                            reloadFunction={this.buscarIndicadores}
                            alertFunction={(indicador, type) => this.enviarMensagem(indicador, type)}
                        />
                        <br />
                        <br />
                    </div>
                    <div id="windows-container" className="high-z-index"></div>
                    <div id="overlay"></div>
                    <Menu reloadFunction={this.buscarIndicadores} />
                </div>
            </div>
        );
    }
}

export default App
