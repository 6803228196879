import React, {Component} from 'react';

const port = process.env.REACT_APP_HOST_PORT || 5000;
const url_host = process.env.REACT_APP_HOST || 'extrator.sysepa.com.br';
const apiURL = `http://${url_host || 'extrator.sysepa.com.br'}:${port}/api`;

class ListaClassificacoes extends Component {
    constructor(props) {
        super(props);
        const {loadClassificacao, removeClassificacao} = this.props;

        this.removeClassificacao = removeClassificacao;
        this.loadClassificacao = loadClassificacao;

        this._getClassificacoes();
        this.state = {
            classificacoes: []
        }

    }

    /**
     *
     * @returns {Promise<void>}
     * @private
     */
    async _getClassificacoes() {
        const headers = new Headers({
            "Content-Type": "application/json",
            Accept: "application/json",
            'empresa': localStorage.getItem('empresa'),
            'codigo': localStorage.getItem('codigo'),
            'auth': localStorage.getItem('auth'),

        });
        const options = {
            method: 'GET',
            headers: headers,
            mode: 'cors',
        };
        fetch(apiURL + `/classificacoes`, options)
            .then(res => res.json())
            .then(response => {
                this.setState({classificacoes: response});
            }).catch(err => {

        });
    };


    render() {
        let {classificacoes} = this.state;
        if (classificacoes === undefined || classificacoes === null || classificacoes.length === 0) return null;
        return (
            <div className="container">
                <div className="row">
                    <div className="cell-9 bg-gray border ">
                        Nome
                    </div>
                    <div className="cell-3 bg-gray border ">
                    </div>
                </div>
                {(classificacoes.length >= 1) ? classificacoes.map((classificacao, id) => {
                    if (classificacao === null) return null;
                    return (
                        <div className="row" key={id}>
                            <div className="cell-9 border bd-gray border-size-1 pt-3">
                                {classificacao.nome}
                            </div>
                            <div className="cell-3 border bd-gray border-size-1 pl-20 p-2">
                                <button
                                    className="button rounded bg-blue mx-1-md"
                                    onClick={() => this.loadClassificacao(classificacao)}
                                >
                                    <span className="mif-pencil" />
                                </button>
                                <button
                                    className="button rounded bg-red mx-1-md"
                                    onClick={() => this.removeClassificacao(classificacao)}
                                >
                                    <span className="mif-cross" />
                                </button>
                            </div>
                        </div>
                    )
                }) : ''
                }
            </div>
        );
    }
}

export default ListaClassificacoes;