import React from 'react';
import InputMask from "react-input-mask";

/**
 *
 * @param props
 * @param {string} props.value
 * @param {name} props.name
 * @param {string} props.label
 * @param {function} props.onChange
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function DateInput(props)
{
    const value = props.value || '';
    const name = props.name || '';
    const label = props.label || null;

    return (
        <InputMask
            data-prepend={label}
            data-role="input"
            data-clear-button="false"
            mask="99/99/9999"
            className="cell-12"
            name={name}
            maskChar=" "
            onChange={props.onChange}
            value={value}
        />
    );
}

export default DateInput;