import React, {Component} from 'react'
import Select from 'react-select';
import Metro from 'metro4/build/js/metro'


const port = process.env.REACT_APP_HOST_PORT || 5000
const url_host = process.env.REACT_APP_HOST || 'extrator.sysepa.com.br'
const apiURL = `http://${url_host || 'extrator.sysepa.com.br'}:${port}/api`

class FormConexoes extends Component {
    constructor(props) {
        super(props)
        const {closeFunction, formType} = this.props;
        this.closeFunction = closeFunction;
        this.formType = formType;

        this.buscaConexoes.bind(this);
        this.state = {
            banco: {},
            nome: '',
            ip: '',
            usuario: '',
            porta: '',
            senha: '',
            database: '',
            bancos: []
        }
    }

    componentDidUpdate(props) {
        if (props.data && props.data.id !== this.state.id) {

            this.setState(data => {
                return props.data;
            })
        }
    }

    handleChangeBanco = (e) => {
        this.setState({banco: e.label})
    };

    handleChange = (e) => {
        const name = e.target.name;
        this.setState({[name]: e.target.value})
    };

    componentDidMount() {
        (async () => await this.buscaConexoes())()
    }

    buscaConexoes = async () => {
        let myHeaders = new Headers();
        myHeaders.append('empresa', localStorage.getItem('empresa'));
        myHeaders.append('codigo', localStorage.getItem('codigo'));
        myHeaders.append('auth', localStorage.getItem('auth'));

        const myInit = {
            method: 'GET',
            headers: myHeaders,
            mode: 'cors',
            cache: 'default'
        };
        const response = await fetch(apiURL + '/conexoes', myInit);
        const body = await response.json();
        if (response.status !== 200) throw Error(body.message);
        let bancos = body.bancos.map((ele, ind) => {
            return {label: ele, value: ind}
        });
        this.setState({bancos: bancos})
    };

    validarConexoes = (data) => {
        return data.banco !== null &&
            data.usuario !== null && data.senha !== null &&
            data.porta !== null && data.database && data.nome &&
            data.ip !== null

    };

    salvarConexoes = () => {
        let data = this.state;
        data.nome = String(localStorage.getItem('empresa')+'_'+data.nome).replace(/ /g, '_');

        if (this.validarConexoes(data)) {
            const headers = new Headers({
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'empresa': localStorage.getItem('empresa'),
                'codigo': localStorage.getItem('codigo'),
                'auth': localStorage.getItem('auth'),
            });
            const options = {
                method: 'POST',
                headers: headers,
                body: JSON.stringify(data),
                mode: 'cors'
            };

            document.getElementById('overlay').style.display = 'block';
            document.getElementById('overlay').style.zIndex = 3;
            fetch(apiURL + '/conexoes', options)
                .then(this.handleErrors)
                .then(res => {
                    this.closeFunction()
                })
                .catch(err => {
                    if (err.message) this.showError(err.message)
                    else this.showError('Houve um problema ao salvar as configurações!')
                })
        } else {
            this.showError('Por favor, confira o preenchimento dos campos')
        }

        return false
    }

    editarConexoes = () => {
        let data = this.state;
        data.nome = String(data.nome).trim().replace(/ /g, '_');

        if (this.validarConexoes(data)) {
            const headers = new Headers({
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'empresa': localStorage.getItem('empresa'),
                'codigo': localStorage.getItem('codigo'),
                'auth': localStorage.getItem('auth'),
            });
            const options = {
                method: 'PUT',
                headers: headers,
                body: JSON.stringify(data),
                mode: 'cors'
            };

            document.getElementById('overlay').style.display = 'block';
            document.getElementById('overlay').style.zIndex = 3;
            fetch(apiURL + '/conexoes/'+this.state.id, options)
                .then(this.handleErrors)
                .then(res => {
                    this.closeFunction()
                })
                .catch(err => {
                    if (err.message) this.showError(err.message)
                    else this.showError('Houve um problema ao salvar as configurações!')
                })
        } else {
            this.showError('Por favor, confira o preenchimento dos campos')
        }

        return false
    }

    showError(message) {
        Metro.dialog.create({
            title: 'Problema ao salvar o Indicador',
            content: `<span class="h4 p-0 m-0">${message}</span>`,
            actions: [{caption: "OK", cls: "js-dialog-close bg-darkRed hover-lightRed fg-white"}],
            defaultAction: {},
            overlayClickClose: true,
            clsTitle: 'bg-darkRed fg-white p-5 m-0',
            clsContent: 'bg-darkRed fg-white p-5 m-0',
            clsAction: 'bg-red fg-white p-5 m-0',
            removeOnClose: true,
            show: true
        })
    }

    render() {
        const buttonSave = <button className="button rounded fg-white bg-green mx-5" onClick={() => this.salvarConexoes()}>Salvar</button>;
        const buttonEdit = <button className="button rounded fg-white bg-green mx-5" onClick={() => this.editarConexoes()}>Editar</button>;
        return (
            <div className="container">
                <form onSubmit={(evt) => evt.preventDefault()}>
                    <div className="grid">
                        <div className="row">
                            <div className="cell-6">
                                <div className="form-group">
                                    <label>Banco de dados <span className="colorRed">*</span></label>
                                    <Select
                                        className="selectStyle"
                                        data-role="select"
                                        placeholder={this.state.id ? "O banco não pode ser alterado" : "Selecione o Banco de dados"}
                                        name="banco"
                                        isDisabled={this.state.id}
                                        value={this.state.banco.label}
                                        onChange={this.handleChangeBanco}
                                        options={this.state.bancos}
                                    />
                                </div>
                            </div>
                            <div className="cell-6">
                                <div className="form-group">
                                    <label>Nome da conexão <span className="colorRed">*</span></label>
                                    <input type="text" data-role="input" placeholder="Informe o identificador da conexão"
                                           name="nome"
                                           value={this.state.nome} onChange={this.handleChange} />
                                    <small className="text-muted">Informe o nome para identificar a conexão</small>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="cell-6">
                                <label>
                                    IP <span className="colorRed">*</span>
                                </label>
                                <input type="text" data-role="input" placeholder="IP da conexão"
                                       name="ip"
                                       value={this.state.ip} onChange={this.handleChange} />
                            </div>
                            <div className="cell-6">
                                <div className="form-group">
                                    <label>Database <span className="colorRed">*</span></label>
                                    <input type="text" data-role="input" placeholder="Database da conexão"
                                           name="database"
                                           value={this.state.database} onChange={this.handleChange} />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="cell-6">
                                <div className="form-group">
                                    <label>Usuário <span className="colorRed">*</span></label>
                                    <input type="text" data-role="input" placeholder="Usuário da conexão"
                                           name="usuario"
                                           value={this.state.usuario} onChange={this.handleChange} />
                                </div>
                            </div>
                            <div className="cell-6">
                                <div className="form-group">
                                    <label>Senha <span className="colorRed">*</span></label>
                                    <input type="password" data-role="input" placeholder="Senha da conexão"
                                           name="senha"
                                           value={this.state.senha} onChange={this.handleChange} />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="cell-6">
                                <div className="form-group">
                                    <label>Porta <span className="colorRed">*</span></label>
                                    <input type="text" data-role="input" placeholder="Porta da conexão"
                                           name="porta"
                                           value={this.state.porta} onChange={this.handleChange} />
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                <div className="text-right">
                    {this.formType == 'save' ? buttonSave : (this.formType == 'edit' ? buttonEdit : '') }
                </div>
            </div>
        )
    }
}

export default FormConexoes
