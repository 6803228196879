import React, { Component } from "react";
import Metro from "metro4/build/js/metro";
import "./FormClassificacao.css";
import ListaClassificacoes from "./listaClassificacoes";

const port = process.env.REACT_APP_HOST_PORT || 5000;
const url_host = process.env.REACT_APP_HOST || 'extrator.sysepa.com.br';
const apiURL = `http://${url_host || 'extrator.sysepa.com.br'}:${port}/api`;

class FormClassificacao extends Component {
	constructor(props) {
		super(props);

		const { classificacao, closeFunction } = this.props;
		this.state = { classificacao: classificacao };
		this.closeFunction = closeFunction;
	}

	validarClassificacao(classificacao) {
		try{
			return (
				classificacao.nome != null 
				&& typeof classificacao.nome === "string"
			);
		} catch (e) {
			return false;
		}
	}

	handleChange = e => {
		const target = e.target;
		const name = target.name;
		let valor = target.type === 'checkbox' ? target.checked : target.value;
		this.setState(state => {
			let ret = state;
			ret.classificacao[name] = valor;
			return ret;
		});
	};

	salvarClassificacao = async () => {
		let {classificacao} = this.state;
		const uniqueId = this.state.classificacao.id ? this.state.classificacao.id : "";

		if (this.validarClassificacao(classificacao)) {
			const headers = new Headers({
				"Content-Type": "application/json",
				Accept: "application/json",
				'empresa': localStorage.getItem('empresa'),
				'codigo': localStorage.getItem('codigo'),
				'auth': localStorage.getItem('auth'),
			});
			const options = {
				method: uniqueId ? "PUT" : "POST",
				headers: headers,
				body: JSON.stringify(classificacao),
				mode: "cors"
			};

			document.getElementById("overlay").style.display = "block";
			try {
				const result = await fetch(apiURL + `/classificacao/${uniqueId}`, options);
				const body = await result.json();

				if (body.error) this.showError(body.error);
				else {
					this.closeFunction();
				}
			} catch (err) {
				if (err.message) this.showError(err.message);
				else
					this.showError(
						"Houve um problema ao buscar as classificações nos dados do EPA"
					);
			}
			document.getElementById("overlay").style.display = "none";
		} else {
			this.showError("Por favor, confira o preenchimento dos campos");
		}

		return false;
	};

	async _removeClassificacao(classificacao){
        const headers = new Headers({
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'empresa': localStorage.getItem('empresa'),
            'codigo': localStorage.getItem('codigo'),
            'auth': localStorage.getItem('auth'),
        });
        const options = {
            method: 'DELETE',
            headers: headers,
            mode: 'cors'
        };
        await fetch(apiURL + `/classificacao/${classificacao.id}`, options);
        this.closeFunction();
	}

	_loadClassificacao(classificacao) {
		this.setState({classificacao});
	}

	showError(message) {
		Metro.dialog.create({
			title: "Problema ao salvar a classificação",
			content: `<span class="h4 p-0 m-0">${message}</span>`,
			actions: [
				{
					caption: "OK",
					cls: "js-dialog-close bg-darkRed hover-lightRed fg-white"
				}
			],
			defaultAction: {},
			overlayClickClose: true,
			clsTitle: "bg-darkRed fg-white p-5 m-0",
			clsContent: "bg-darkRed fg-white p-5 m-0",
			clsAction: "bg-red fg-white p-5 m-0",
			removeOnClose: true,
			show: true
		});
	}

	render() {
		return (
			<div className="container">
				<form onSubmit={evt => evt.preventDefault()}>
					<div className="grid">
						<div className="row">
							<div className="cell-10">
								<div className="form-group">
									<label>Nome</label>
									<input
										type="text"
										data-role="input"
										placeholder="Nome da classificação"
										value={this.state.classificacao.nome}
										name="nome"
										onChange={this.handleChange}
									/>
								</div>
							</div>
							<div className="cell-2">
								<div className="text-right pt-6">
									<button
										className="button rounded bg-green bg-lightGreen-hover mx-5"
										onClick={() => this.salvarClassificacao()}
									>
										<span className="fg-white">
											{(this.state.classificacao.id)?'Editar':'Salvar'}
										</span>
									</button>
								</div>
							</div>
						</div>
					</div>
				</form>
				<div className="row">
					<div className="cell">
						<div>
							<ListaClassificacoes
								removeClassificacao={(e) => this._removeClassificacao(e)}
								loadClassificacao={(e) => this._loadClassificacao(e)}
							/>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default FormClassificacao;
