import React, {Component} from 'react';
import Metro from 'metro4/build/js/metro'

const port = process.env.REACT_APP_HOST_PORT || 5000;
const url_host = process.env.REACT_APP_HOST || 'extrator.sysepa.com.br';
const apiURL = `http://${url_host || 'extrator.sysepa.com.br'}:${port}/api`;

class ListaConexoes extends Component {
    constructor(props) {
        super(props);
        const {closeFunction, loadConexao, removeConexao, validateConnection} = this.props;
        this.closeFunction = closeFunction;
        this.loadConexao = loadConexao;
        this.removeConexao = removeConexao;
        this.validateConnection = validateConnection;
        this._getConexoes();
        this.state = {
            conexoes: []
        }

    }

    /**
     *
     * @returns {Promise<void>}
     * @private
     */
    async _getConexoes() {
        const headers = new Headers({
            "Content-Type": "application/json",
            Accept: "application/json",
            'empresa': localStorage.getItem('empresa'),
            'codigo': localStorage.getItem('codigo'),
            'auth': localStorage.getItem('auth'),

        });
        const options = {
            method: 'GET',
            headers: headers,
            mode: 'cors',
        };
        fetch(apiURL + `/conexoes/lista`, options)
            .then(res => res.json())
            .then(response => {
                this.setState({conexoes: response});
            }).catch(err => {

        });
    };


    render() {
        let {conexoes} = this.state;
        if (conexoes === undefined || conexoes === null || conexoes.length === 0) return null;
        return (
            <div className="container">
                <div className="row">
                    <div className="cell-3 bg-gray border ">
                        Nome
                    </div>
                    <div className="cell-2 bg-gray border ">
                        Banco
                    </div>
                    <div className="cell-2 bg-gray border ">
                        IP
                    </div>
                    <div className="cell-2 bg-gray border ">
                        Usuário
                    </div>
                    <div className="cell-3 bg-gray border ">
                    </div>
                </div>
                {(conexoes.length >= 1) ? conexoes.map((conexao, id) => {
                    if (conexao === null) return null;
                    return (
                        <div className="row" key={id}>
                            <div className="cell-3 border bd-gray border-size-1 pt-3">
                                {conexao.nome}
                            </div>
                            <div className="cell-2 border bd-gray border-size-1 pt-3">
                                {conexao.banco}
                            </div>
                            <div className="cell-2 border bd-gray border-size-1 pt-3">
                                {conexao.ip}
                            </div>
                            <div className="cell-2 border bd-gray border-size-1 pt-3">
                                {conexao.usuario}
                            </div>
                            <div className="cell-3 border bd-gray border-size-1 pl-20 p-2">
                                <button
                                    className="button rounded bg-blue mx-1-md"
                                    onClick={() => this.loadConexao(conexao)}
                                >
                                    <span className="mif-pencil" />
                                </button>
                                {(conexao.indicador.length >= 1) ? 
                                    (<button
                                        className="button rounded bg-gray mx-1-md"
                                        title="Existe indicador configurado com esa conexão"
                                    >
                                        <span className="mif-cross" />
                                    </button>) 
                                    : 
                                    (<button
                                        className="button rounded bg-red mx-1-md"
                                        onClick={() => this.removeConexao(conexao)}
                                    >
                                        <span className="mif-cross" />
                                    </button>) 
                                }
                                
                                <button
                                    className="button rounded bg-green mx-1-md"
                                    onClick={() => this.validateConnection(conexao)}
                                >
                                    <span className="mif-database" />
                                </button>
                            </div>
                        </div>
                    )
                }) : ''
                }
            </div>
        );
    }
}

export default ListaConexoes;