import React, { Component } from 'react'
import FormConfiguracoes from '../FormConfiguracoes/FormConfiguracoes'
import MapaSincronizacoes from '../MapaSincronizacoes/MapaSincronizacoes'

import './WindowConfiguracoes.css'

class WindowConfiguracoes extends Component {
  constructor(props) {
    super(props)

    const { closeFunction } = this.props
    this.closeFunction = closeFunction

    this.initialState = {
      odbcString: ''
    }

    this.state = this.initialState
    this.windowId = 'window-configuracoes'
    this.title = 'Configurações do Extrator'
  }

  componentDidMount() {
    document.getElementById('overlay').style.display = 'block'
    document.getElementById('overlay').style.zIndex = 3
  }

  render() {
    const form = <FormConfiguracoes closeFunction={this.closeFunction} />

    return (
      <div className="window configuracoes-window w-50" id={this.windowId}>
        <div className="window-caption">
          <span className="icon mif-pencil"></span>
          <span className="title">{this.title}</span>
          <div className="buttons">
            <span className="btn-close" onClick={() => this.closeFunction()}></span>
          </div>
        </div>
        <div className="p-2">
          <div className="grid">
            <div className="row">
              <div className="cell">{form}</div>
            </div>
            <div className="row">
              <div className="cell">
                <MapaSincronizacoes />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default WindowConfiguracoes