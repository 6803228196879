import React, { Component } from 'react'
import FormClassificacao from '../FormClassificacao/FormClassificacao'

import './WindowClassificacao.css'

class WindowClassificacao extends Component {
  constructor(props) {
    super(props)

    const { closeFunction } = this.props
    this.closeFunction = closeFunction

    this.initialState = {
      nome: ''
    };


    this.state = this.initialState
    this.windowId = 'window-classificacao-new'
    this.title = 'Nova Classificação'
    
  }

  componentDidMount() {
    document.getElementById('overlay').style.display = 'block'
    document.getElementById('overlay').style.zIndex = 3
  }

  render() {
    const form = <FormClassificacao classificacao={this.state} closeFunction={this.closeFunction} />

    return (
      <div className="window classificacao-window w-50" id={this.windowId}>
        <div className="window-caption">
          <span className="icon mif-pencil"></span>
          <span className="title">{this.title}</span>
          <div className="buttons">
            <span className="btn-close" onClick={() => this.closeFunction()}></span>
          </div>
        </div>
        <div className="window-content p-2">
          {form}
        </div>
      </div>
    )
  }
}

export default WindowClassificacao