import React, {Component} from 'react'
import './WindowLogs.css'
import Metro from 'metro4/build/js/metro'
import DataTable from 'react-data-table-component'


const port = process.env.REACT_APP_HOST_PORT || 5000;
const url_host = process.env.REACT_APP_HOST || 'extrator.sysepa.com.br';
const apiURL = `http://${url_host || 'extrator.sysepa.com.br'}:${port}/api`;

const columns = [
    {
        name: 'Data',
        selector: 'horario',
         minWidth: '15%',
        center: true,
        sortable: true,
    },
    {
        name: 'Indicador',
        selector: 'indicador',
        center: true,
         minWidth: '12%',
        wrap: true,
    },
    {
        name: 'Mensagem',
        selector: 'mensagem',
        minWidth: '50%',
        wrap: true,
    },
    {
        name: 'Tipo',
        selector: 'tipo',
        center: true,
        wrap: true,
         minWidth: '11%',
    }
];
const SqlContent = ({data}) => <div className="p-5 scroll-y" style={{maxHeight: '120px'}}>Consulta: {data.sql}</div>;
const LoadingPage = () => <h3>Carregando</h3>;
class WindowLogs extends Component {
    constructor(props) {
        super(props);

        const {closeFunction} = this.props;
        this.closeFunction = closeFunction;

        this.initialState = {
            odbcString: '',
            newOrEdit: false,
            data: null,
        };

        this.state = this.initialState;
        this.windowId = 'window-Logs';
        this.title = 'Logs do Extrator';
        this.state.data = [
        ]
    }

    async getLogs(page, totalRows) {
        page = page || 1;
        this.setState({loadingProgress: true});
        this.setState({data: {}});
        const headers = new Headers({
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'empresa': localStorage.getItem('empresa'),
            'codigo': localStorage.getItem('codigo'),
            'auth': localStorage.getItem('auth'),
        });
        const options = {
            method: 'GET',
            headers: headers,
            mode: 'cors'
        };
        const response = await fetch(apiURL + `/logs?pagina=${page}&empresa=${localStorage.getItem('empresa')}`, options);
        const body = await response.json();
        this.setState({data: body.data}, function () {
            this.setState({loadingProgress: false});
        }.bind(this));
        this.setState({quantidade: body.quantidade})
    }
    componentDidMount() {
        document.getElementById('overlay').style.display = 'block';
        document.getElementById('overlay').style.zIndex = 3;
        this.getLogs();
    }

    showMessage(message, type) {
        if (type === 'success') {
            Metro.dialog.create({
                title: 'Ótimo!',
                content: `<span class="h4 p-0 m-0">${message}</span>`,
                actions: [{caption: "OK", cls: "js-dialog-close bg-darkBlue hover-lightBlue fg-white"}],
                defaultAction: {},
                overlayClickClose: true,
                clsTitle: 'bg-darkGreen fg-white p-5 m-0',
                clsContent: 'bg-darkGreen fg-white p-5 m-0',
                clsAction: 'bg-darkGreen fg-white p-5 m-0',
                removeOnClose: true,
                show: true
            })
        } else {
            Metro.dialog.create({
                title: 'Falha de conexão',
                content: `<span class="h4 p-0 m-0">${message}</span>`,
                actions: [{caption: "OK", cls: "js-dialog-close bg-darkRed hover-lightRed fg-white"}],
                defaultAction: {},
                overlayClickClose: true,
                clsTitle: 'bg-darkRed fg-white p-5 m-0',
                clsContent: 'bg-darkRed fg-white p-5 m-0',
                clsAction: 'bg-red fg-white p-5 m-0',
                removeOnClose: true,
                show: true
            })
        }

    }

    render() {
        return (
            <div className="window Logs-window" id={this.windowId}>
                <div className="window-caption">
                    <span className="icon mif-pencil" />
                    <span className="title">{this.title}</span>
                    <div className="buttons">
                        <span className="btn-close" onClick={() => this.closeFunction()} />
                    </div>
                </div>
                <div className="p-2">
                    <div className="grid">
                        <div className="row">
                            <div className="cell scroll-y overflow">
                                <div>
                                    <DataTable
                                        data={this.state.data}
                                        className={"TableSizes scroll-y"}
                                        hide={"md"}
                                        columns={columns}
                                        pagination
                                        paginationComponentOptions={
                                            {
                                                rowsPerPageText: 'Linhas por página:',
                                                rangeSeparatorText: 'de', noRowsPerPage: true
                                            }
                                        }
                                        paginationPerPage={"8"}
                                        paginationServer
                                        onChangePage={(page, totalRows) => this.getLogs(page, totalRows)}
                                        paginationTotalRows={this.state.quantidade}
                                        expandableRows
                                        noDataComponent={"Nenhum dado a ser mostrado"}
                                        progressPending={this.state.loadingProgress}
                                        progressComponent={<LoadingPage />}
                                        responsive
                                        noHeader
                                        expandableRowDisabled={row => row.sql === null}
                                        expandableRowsComponent={<SqlContent />}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default WindowLogs