import React, { Component } from "react";
import Select from 'react-select';
import Metro from "metro4/build/js/metro";
import "./FormIndicador.css";


const port = process.env.REACT_APP_HOST_PORT || 5000;
const url_host = process.env.REACT_APP_HOST || 'extrator.sysepa.com.br';
const apiURL = `http://${url_host || 'extrator.sysepa.com.br'}:${port}/api`;
class FormIndicador extends Component {
	constructor(props) {
		super(props);

		const { indicador, closeFunction } = this.props;

		this.state = {
			indicador: indicador, 

			nome: indicador.nome ? indicador.nome : "",
			sqlScript: indicador.sql_script ? indicador.sql_script : "",
			dateFormat: indicador.date_format ? indicador.date_format : "",
			codigoEpa: indicador.codigo_epa ? indicador.codigo_epa : 0,
			horariosSincronizacao: indicador.horarios_sincronizacao ? indicador.horarios_sincronizacao : [],
			retroativo: indicador.retroativo ? indicador.retroativo : null,
			mensal: indicador.mensal ? indicador.mensal : 0,
			final_mes: indicador.final_mes ? indicador.final_mes : 0,
			importar_usuario: indicador.importar_usuario ? indicador.importar_usuario : 0,

			classificacoes: [], 
			bancos: [], 
			selectedODBC: indicador.conexao 
				? {label: indicador.conexao.nome, value: indicador.conexao.id} 
				: null,
			tipoIndicador: indicador.tipo_indicador 
				? {label: indicador.tipo_indicador, value: indicador.tipo_indicador} 
				: null,
			selectedClassificacao: indicador.classificacao 
				? {label: indicador.classificacao.nome, value: indicador.classificacao.id} 
				: null,

			exiteDataNoSql: false,
		};

		this.closeFunction = closeFunction;
		this.buscaConexoes();
		this.buscaClassificoes();
	}

	validarIndicador(indicador) {

		try{
			return (
				// indicador.nome != null &&
				// typeof indicador.nome === "string" &&
				// indicador.nome.length > 0 &&
				// indicador.sqlScript != null &&
				// typeof indicador.sqlScript === "string" &&
				// indicador.sqlScript.length > 0 &&
				// indicador.dateFormat != null &&
				// typeof indicador.dateFormat === "string" &&
				// indicador.dateFormat.length > 0  &&
				// Array.isArray(indicador.horariosSincronizacao) &&
				// Object.keys(indicador.odbcString).length > 0 &&
				// Object.keys(indicador.tipoIndicador).length > 0 &&
				// (indicador.codigoEpa != null && !isNaN(indicador.codigoEpa) &&
				// parseInt(indicador.codigoEpa) > 0 || (indicador.tipoIndicador.label !== 3 || indicador.tipoIndicador.label !== 0))
				true
			);
		} catch (e) {
			return false;
		}
	}

	handleChange = e => {
		
		const target = e.target;
		const name = target.name;
		let valor = target.type === 'checkbox' ? target.checked : target.value;
		
		if(name == 'sqlScript'){
			let dataInicio = this.state.sqlScript.indexOf("$datainicio");
			let dataFim = this.state.sqlScript.indexOf("$datafim");

			(dataInicio == -1 || dataFim == -1)
				? this.setState({ exiteDataNoSql: true }) 
				: this.setState({ exiteDataNoSql: false });
		}

		this.setState({[name]: valor});

	};

	handleErrors(response) {
		if (!response.ok) {
			throw new Error(response.error);
		}
		return response;
	}

	handleChangeBanco = (selectedODBC) => {
		this.setState({selectedODBC});
	};

	handleChangeClassificacao = (selectedClassificacao) => {
		this.setState({selectedClassificacao});
	};

	handleChangeTipo = (tipoIndicador) => {
		this.setState({tipoIndicador});
	};

	buscaConexoes = async () => {
		let myHeaders = new Headers();
		myHeaders.append('empresa', localStorage.getItem('empresa'));
		myHeaders.append('codigo', localStorage.getItem('codigo'));
		myHeaders.append('auth', localStorage.getItem('auth'));
		
		const myInit = { 
			method: 'GET',
			headers: myHeaders,
			mode: 'cors',
			cache: 'default' 
		};
		const response = await fetch(apiURL + '/conexoes/lista', myInit);
		const body = await response.json();
		if (response.status !== 200) throw Error(body.message);
		let bancos = body.map((ele, ind) => {
			return {label: ele.nome, value: ele.id}
		});

		this.setState({ bancos: bancos })
	};

	buscaClassificoes = async () => {
		let myHeaders = new Headers();
		myHeaders.append('empresa', localStorage.getItem('empresa'));
		myHeaders.append('codigo', localStorage.getItem('codigo'));
		myHeaders.append('auth', localStorage.getItem('auth'));
		
		const myInit = { 
			method: 'GET',
			headers: myHeaders,
			mode: 'cors',
			cache: 'default' 
		};
		const response = await fetch(apiURL + '/classificacoes', myInit);
		const body = await response.json();
		if (response.status !== 200) throw Error(body.message);
		let classificacoes = body.map((ele, ind) => {
			return {label: ele.nome, value: ele.id}
		});

		this.setState({ classificacoes: classificacoes })
	};

	options(){
        let data = [];
        this.state.bancos.map((val, ind) => {
            data.push(val);
            return val;
		});
        return data;
	}
	
	optionsClassificacoes(){
        let data = [];
        this.state.classificacoes.map((val, ind) => {
            data.push(val);
            return val;
		});
        return data;
    }

	salvarIndicador = async () => {

		let {indicador} = this.state;
		const uniqueId = this.state.indicador.id ? this.state.indicador.id : "";

		indicador.nome = this.state.nome;
		indicador.sqlScript = this.state.sqlScript;
		indicador.dateFormat = this.state.dateFormat;
		indicador.codigoEpa = this.state.codigoEpa;
		indicador.retroativo = this.state.retroativo;
		indicador.mensal = this.state.mensal;
		indicador.final_mes = this.state.final_mes;
		indicador.importar_usuario = this.state.importar_usuario;
		
		
		indicador.odbcString = this.state.selectedODBC;
		indicador.classificaoSeleciona = this.state.selectedClassificacao;
		indicador.tipoIndicador = this.state.tipoIndicador;

		indicador.horariosSincronizacao = document
			.getElementById("horarios-sincronizacao")
			.value.split(",")
			.filter(horario => horario.length > 0);

		if (this.validarIndicador(indicador)) {
			const headers = new Headers({
				"Content-Type": "application/json",
				Accept: "application/json",
				'empresa': localStorage.getItem('empresa'),
				'codigo': localStorage.getItem('codigo'),
				'auth': localStorage.getItem('auth'),
			});
			const options = {
				method: uniqueId ? "PUT" : "POST",
				headers: headers,
				body: JSON.stringify(indicador),
				mode: "cors"
			};

			document.getElementById("overlay").style.display = "block";
			try {
				const result = await fetch(apiURL + `/indicador/${uniqueId}`, options);
				const body = await result.json();

				if (body.error) this.showError(body.error);
				else {
					this.closeFunction();
				}
			} catch (err) {
				if (err.message) this.showError(err.message);
				else
					this.showError(
						"Houve um problema ao buscar os indicadores no 	 de dados do EPA"
					);
			}
			document.getElementById("overlay").style.display = "none";
		} else {
			this.showError("Por favor, confira o preenchimento dos campos");
		}

		return false;
	};

	showError(message) {
		Metro.dialog.create({
			title: "Problema ao salvar o Indicador",
			content: `<span class="h4 p-0 m-0">${message}</span>`,
			actions: [
				{
					caption: "OK",
					cls: "js-dialog-close bg-darkRed hover-lightRed fg-white"
				}
			],
			defaultAction: {},
			overlayClickClose: true,
			clsTitle: "bg-darkRed fg-white p-5 m-0",
			clsContent: "bg-darkRed fg-white p-5 m-0",
			clsAction: "bg-red fg-white p-5 m-0",
			removeOnClose: true,
			show: true
		});
	}

	componentDidMount() {
		if (this.state.indicador.horarios_sincronizacao) {
			document.getElementById(
				"horarios-sincronizacao"
			).value = this.state.indicador.horarios_sincronizacao;
		}
	}

	render() {
		const charsCounterId = "chars-counter-" + this.state.indicador.id;

		return (
			<div className="container">
				<form onSubmit={evt => evt.preventDefault()}>
					<div className="grid">
						<div className="row">
							<div className="cell-4">
								<div className="form-group">
									<label>Tipo de indicador</label> 
									<Select
										className="selectStyleSuperior"
										data-role="select"
										placeholder="Selecione o tipo"
										name="tipoIndicador"
										value={this.state.tipoIndicador}
										onChange={this.handleChangeTipo}
										options={[
											{ label: 'Indicador', value: 'Indicador'}, 
											{ label: 'Alvo', value: 'Alvo'},
											{ label: 'Clientes', value: 'Clientes'}
										]}
									/>
								</div>
							</div>
							<div className="cell-6">
								<div className="form-group">
									<label>Nome</label>
									<input
										type="text"
										data-role="input"
										placeholder="Nome do Indicador"
										value={this.state.nome}
										name="nome"
										onChange={this.handleChange}
									/>
								</div>
							</div>
							<div className="cell-2">
								<div className="form-group">
									<label>Código do EPA</label>
									<input
										type="text"
										min="0"
										data-role="input"
										placeholder="999"
										name="codigoEpa"
										disabled={this.state.indicador.id ? true : false}
										value={this.state.codigoEpa}
										onChange={this.handleChange}
									/>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="cell-6">
								<div className="form-group">
									<label>Conexão</label> 
									<Select
										data-role="select"
										placeholder="Selecione a conexão"
										name="conexao"
										value={this.state.selectedODBC}
										onChange={this.handleChangeBanco}
										options={this.options()}
									/>
								</div>
							</div>
							<div className="cell-3">
								<div className="form-group">
									<label>Horários de Sincronização</label>
									<input
										type="text"
										id="horarios-sincronizacao"
										data-on-before-tag-add="validarHorariosSincronizacao"
										data-role="taginput"
										data-cls-tag="bg-cyan fg-white"
										data-cls-tag-remover="bg-red fg-white"
									/>
									<small className="text-muted">
										Insira uma "," para separar os horários
                  					</small>
								</div>
							</div>
							<div className="cell-3">
								<div className="form-group">
									<label>Formato de Data</label>
									<input
										type="text"
										data-role="input"
										placeholder="yyyy-MM-dd HH:mm:ss"
										name="dateFormat"
										value={this.state.dateFormat}
										onChange={this.handleChange}
									/>
									<small className="text-muted">
										Formato do banco de origem
                  					</small>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="cell-6">
								<div className="form-group">
									<label>Classificação</label> 
									<Select
										data-role="select"
										placeholder="Classificação do indicador"
										name="classificacao"
										value={this.state.selectedClassificacao}
										onChange={this.handleChangeClassificacao}
										options={this.optionsClassificacoes()}
									/>
								</div>
							</div>
							<div className="cell-3">
								<label>Qtde. Mês retroativo</label>
								<input
									type="text"
									data-role=""
									placeholder="Extrair meses retroativos"
									defaultValue={this.state.retroativo}
									id="retroativo"
									name="retroativo"
									onChange={this.handleChange}
								/>
							</div>
							<div className="cell-3 check-cell">
								<label>Mês a mês</label>
								<br/>
									<input
										type="checkbox"
										data-role=""
										className="checkbox"
										disabled={this.state.tipoIndicador && this.state.tipoIndicador.value === 6}
										checked={this.state.mensal && this.state.tipoIndicador.value !== 6}
										id="mensal"
										name="mensal"
										onChange={this.handleChange}
									/>
							</div>
							
						</div>
						<div className="row">
							<div className="cell-6 check-cell box-final-mes">
								<input
									type="checkbox"
									data-role=""
									className="checkbox"
									checked={this.state.final_mes }
									id="final_mes"
									name="final_mes"
									onChange={this.handleChange}
								/>
								<label>Importar registros considerando mês completo</label>
									
							</div>
							<div className="cell-6 check-cell box-final-mes">
								<input
									type="checkbox"
									data-role=""
									className="checkbox"
									checked={this.state.importar_usuario }
									id="importar_usuario"
									name="importar_usuario"
									onChange={this.handleChange}
								/>
								<label>Importar como usuários do sistema</label>
									
							</div>
						</div>
						<div className="row">
							<div className="cell-12">
								<div className="form-group">
									<div>
										<label>Consulta SQL</label>
										<div className="textarea-container">
											<textarea
												rows="8"
												data-role="text"
												data-chars-counter={"#" + charsCounterId}
												data-chars-counter-template="$1 caracteres"
												data-auto-size="true"
												name="sqlScript"
												value={this.state.sqlScript}
												onChange={this.handleChange}
											/>
										</div>
										{(this.state.exiteDataNoSql)?
											<p style={{color: "red"}}>Adicione os campos $datainicio e $datafim no SQL</p>:''
										}
										
									</div>
									<small id={charsCounterId}/>
								</div>
							</div>
						</div>
					</div>
				</form>
				<div className="text-right pb-10">
					<button
						className="button rounded bg-green bg-lightGreen-hover mx-5"
						onClick={() => this.salvarIndicador()}
					>
						<span className="fg-white">
							Salvar
						</span>
          			</button>
				</div>
			</div>
		);
	}
}

export default FormIndicador;
