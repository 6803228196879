import React, { Component } from 'react'

import Metro from 'metro4/build/js/metro'


const port = process.env.REACT_APP_HOST_PORT || 5000
const url_host = process.env.REACT_APP_HOST || 'extrator.sysepa.com.br'
const apiURL = `http://${url_host || 'extrator.sysepa.com.br'}:${port}/api`

class FormConfiguracoes extends Component {
  constructor(props) {
    super(props)
    
    const { closeFunction } = this.props
    
    this.closeFunction = closeFunction
    this.buscarConfiguracoes.bind(this)
    this.sincronizarComEpa.bind(this)

    this.state = {
      odbcString: '',
      indicadores: 0,
    }
  }
  
  validarConfiguracoes(configs) {
    return true
  }
  
  handleChange = (e) => {
    const name = e.target.name
    this.setState({ [name]: e.target.value })
  }
  
  handleErrors(response) {
    if (!response.ok) {
      throw new Error(response.error)
    }
    return response
  }

  componentDidMount() {
    (async () => await this.buscarConfiguracoes())()
  }

  buscarConfiguracoes = async () => {
    let myHeaders = new Headers();
    myHeaders.append('empresa', localStorage.getItem('empresa'));
    myHeaders.append('codigo', localStorage.getItem('codigo'));
    myHeaders.append('auth', localStorage.getItem('auth'));
    
    const myInit = { 
      method: 'GET',
      headers: myHeaders,
      mode: 'cors',
      cache: 'default' 
    };

    const response = await fetch(apiURL + '/configuracoes', myInit)
    const body = await response.json()
    if (response.status !== 200) throw Error(body.message)

    this.setState({ odbcString: body.odbcString, indicadores: body.indicadores })
  }

  salvarConfiguracoes = () => {
    let configs = this.state
    
    if(this.validarConfiguracoes(configs)) {
      const headers = new Headers({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'empresa': localStorage.getItem('empresa'),
        'codigo': localStorage.getItem('codigo'),
        'auth': localStorage.getItem('auth'),
      })
      const options = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(configs),
        mode: 'cors'
      }
      
      document.getElementById('overlay').style.display = 'block'
      document.getElementById('overlay').style.zIndex = 3
      fetch(apiURL + '/configuracoes/', options)
        .then(this.handleErrors)
        .then(res => {
          this.closeFunction()
        })
        .catch(err => {
          if(err.message) this.showError(err.message)
          else this.showError('Houve um problema ao salvar as configurações!')
        })
    } else {
      this.showError('Por favor, confira o preenchimento dos campos')
    }
    
    return false
  }

  sincronizarComEpa = async function() {

    if (typeof this.state.odbcString !== 'string') return this.showError('Primeiro configure uma String de conexão ODBC')

    const headers = new Headers({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'empresa': localStorage.getItem('empresa'),
      'codigo': localStorage.getItem('codigo'),
      'auth': localStorage.getItem('auth'),
    })

    const options = {
      method: 'POST',
      headers: headers,
      body: JSON.stringify({ odbcString: this.state.odbcString }),
      mode: 'cors'
    }

    document.getElementById('overlay').style.display = 'block'
    document.getElementById('overlay').style.zIndex = 3
    try {
      const result = await fetch(apiURL + '/configuracoes/sync', options)
      const body = await result.json()

      if(body.error) this.showError(body.error)
      else {
        this.closeFunction()
      }
    } catch(err) {
      if(err.message) this.showError(err.message)
      else this.showError('Houve um problema ao buscar os indicadores no banco de dados do EPA')
    }
    document.getElementById('overlay').style.display = 'none'
  }
  
  showError(message) {
    Metro.dialog.create({
      title: 'Houve uma falha ao executar esta operação',
      content: `<span class="h4 p-0 m-0">${message}</span>`,
      actions: [ { caption: "OK", cls: "js-dialog-close bg-darkRed hover-lightRed fg-white" } ],
      defaultAction: {},
      overlayClickClose: true,
      clsTitle: 'bg-darkRed fg-white p-5 m-0',
      clsContent: 'bg-darkRed fg-white p-5 m-0',
      clsAction: 'bg-red fg-white p-5 m-0',
      removeOnClose: true,
      show: true
    })
  }

  render() {
    return (
      <div className="container">
        {/* <form onSubmit={ (evt) => evt.preventDefault() }>
          <div className="grid">
            <div className="row">
              <div className="cell-12">
                <div className="form-group">
                  <label>Conexão ODBC</label>
                  <input type="text" data-role="input" placeholder="DSN=myDsn;Uid=myUsername;Pwd=;"
                      name="odbcString"
                      value={this.state.odbcString} onChange={this.handleChange} />
                  <small className="text-muted">Informe a String de Conexão com o driver ODBC</small>
                </div>
              </div>
            </div>
          </div>
        </form> */}
        <div className="text-center">
          <button 
            className="button rounded fg-white bg-blue mx-5" 
            disabled={this.state.indicadores > 0} 
            onClick={() => this.sincronizarComEpa()} 
          >
            <span className="mif-loop"></span> Sincronizar Indicadores com o EPA
          </button>
          {/* <button className="button rounded fg-white bg-green mx-5" onClick={() => this.salvarConfiguracoes()}>
            Salvar
          </button> */}
        </div>
      </div>
      )
    }
  }
  
  export default FormConfiguracoes